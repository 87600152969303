import { getNumberOfDaysBetween } from '@/utils/dateUtil'

const CELL_WIDTH = 100
const CELL_HEIGHT = 20

export function calculateOverlpas (dataArray, getId, filterFromDate, filterToDate) {
  const result = {}

  dataArray
    .sort((a, b) => (a.block === b.block) ? 0 : a.block ? 1 : -1) // must sort normal -> block so that the block rsv always appear below other reservations
    .forEach((rsv) => {
      const parkingId = getId(rsv)
      if (!result[parkingId]) {
        result[parkingId] = {
          normals: [],
          blocks: [],
          maxOverlap: 0
        }
      }
      const allRsvs = [...result[parkingId].blocks, ...result[parkingId].normals]
      const targetRsvs = rsv.block ? result[parkingId].blocks : result[parkingId].normals
      // determine the left first, which is simple.
      // dateFrom - date selected * 100px
      const leftDays = getNumberOfDaysBetween(filterFromDate, rsv.fromDate)
      const left = CELL_WIDTH * leftDays
      const fromOverflow = filterFromDate > rsv.fromDateRoot
      const toOverflow = filterToDate < rsv.toDate

      // now the top is a bit complicated, we need to know how many parkings woud overlap with this rsv fromDate to toDate
      // probably there are more efficient algorithms but for now just brut-force should suffice for this use case.
      const pastOverlaps = new Set(allRsvs.filter((rsv2) => {
        return rsv2.fromDate < rsv.toDate && rsv2.toDate > rsv.fromDate
      }).map(rsv2 => rsv2.overlaps))
      let overlaps = 0
      for (;overlaps < pastOverlaps.size; overlaps++) {
        if (!pastOverlaps.has(overlaps)) {
          break
        }
      }
      if (result[parkingId].maxOverlap < overlaps) {
        result[parkingId].maxOverlap = overlaps
      }
      const days = getNumberOfDaysBetween(rsv.fromDate, toOverflow ? filterToDate : rsv.toDate)

      const top = overlaps * CELL_HEIGHT
      const width = (days +
      (
        (
          (fromOverflow ? 0.5 : 0) +
          (toOverflow ? 0.5 : 0)
        )
      )
      ) * CELL_WIDTH

      let style = `width: ${width}px; top: ${top}px; left: ${left}px;`
      if (fromOverflow) {
        style += 'margin-left: 0px;'
      }

      const expanded = {
        ...rsv,
        overlaps,
        style,
        reverseStyle (qty) {
          return `width: ${width}px; top: ${(qty - 1) * CELL_HEIGHT - top}px; left: ${left}px;`
        }
      }
      targetRsvs.push(expanded)
    })

  return result
}
